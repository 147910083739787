.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.loader-container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}

.js-modal-dialog {
  background: #35393D;
  border-radius: 8px;
  padding: 30px;
}

.js-modal-dialog-content {
  padding: 20px 40px 25px;
}

.modal-c-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  transition: color 50ms ease-in-out;
  background: rgba(39, 42, 51, 0.8);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.modal-c-backdrop-double {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1080;
  transition: color 50ms ease-in-out;
  background: rgba(39, 42, 51, 0.8);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.overflow-hidden {
  overflow: hidden;
}
.grecaptcha-badge {
  z-index: 1;
}

@media screen and (max-width: 1080px) {
  .modal-c-backdrop,
  .modal-c-backdrop-double {
    padding: 15px;
  }
}