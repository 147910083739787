.table-top {
  background-color: #434968;
  width: 100%;
  height: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.table-bottom {
  width: 100%;
  height: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid #434968;
  border-left: 1px solid #434968;
  border-right: 1px solid #565c7d;
  margin-top: -1px;
}

.table {
  width: 100%;
  background-color: transparent;
  border-radius: 10px;
  border-collapse: collapse;
  /* table-layout: fixed; */
  /* overflow-y: auto; */
  margin-top: -1px;
  border-left: 1px solid #434968;
  border-right: 1px solid #434968;
}

.table thead > tr th {
  background-color: #434968;
  padding: 6px 20px 16px;
  text-align: left;
  text-transform: uppercase;
}
.table thead > tr {
  border-left: 1px solid #434968;
  border-right: 1px solid #434968;
}

.table tbody > tr:not(:last-of-type) {
  border-bottom: 1px solid #434968;
}

.table tbody td {
  padding: 18px 20px;
  color: #B6BEE3;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  word-wrap: break-word;
}
.table tbody > tr:last-of-type td {
  padding-bottom: 8px;
}

@media screen and (max-width: 920px) {
  .table tbody td {
    font-size: 13px;
    line-height: 22px;
    white-space: nowrap;
    min-width: max-content;
  }
  .table thead > tr th {
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    width: max-content;
  }
  .table-top,
  .table-bottom {
   
    /* width: 100vw;  */
    /* width: calc(100vw - 30px); */
  }
}
