.main-page {
  font-size: 15px;
  line-height: 150%;
  padding-top: 53px;
}

.bg-container {
  position: relative;
  overflow: hidden;
}
.bg-container_back,
.bg-container_left,
.bg-container_top {
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  max-width: 1900px;
  width: 100%;
  height: 900px;
  position: absolute;
  display: block;
  left: 25%;
}
.bg-container_back {
  background-position: center bottom;
  background-image: url('../../assets/images/main/Bg_gr_back.png');
  animation: bgBack 5s ease-in-out infinite;
}
@keyframes bgBack {
  0% {
    transform: scale(1) rotate(0deg);
  }
  40% {
    transform: scale(1.8) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}
.bg-container_left {
  background-image: url('../../assets/images/main/Bg_gr_left.png');
  background-position: left bottom;
  animation: bgLeft 10s ease-in-out infinite;
}

@keyframes bgLeft {
  0% {
    transform: scale(1);
    filter: contrast(100%);
  }
  50% {
    transform: scale(1.1);
    filter: contrast(170%);
  }
  100% {
    transform: scale(1);
    filter: contrast(100%);
  }
}
.bg-container_top {
  background-image: url('../../assets/images/main/Bg_gr_top.png');
  background-position: center bottom;
  animation: bgTop 10s ease-in-out infinite;
}

@keyframes bgTop {
  0% {
    transform: translateX(0) scale(1);
    filter: brightness(1);
  }
  20% {
    transform: translateX(-20%) scale(1.1);
    filter: brightness(0.7);
  }
  60% {
    transform: translateX(10%) scale(1.3);
    filter: brightness(0.4);
  }
  100% {
    transform: translateX(0) scale(1);
    filter: brightness(1);
  }
}

.bubble_container {
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #ffffff;
  padding: 210px 0 170px;
}
.bubble_container .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-logo {
  margin-bottom: 45px;
}

.main-logo img {
  width: 105px;
  height: 102px;
}

.bubble_container h1 {
  font-size: 80px;
  line-height: 111%;
  text-align: center;
  font-family: Manrope;
  text-transform: uppercase;
  width: 770px;
  margin-bottom: 17px;
  overflow: hidden;
}

.title-word {
  animation: slideUp 1.2s forwards;
  display: inline-block;
  transform: translateY(400%);
}
.title-word-wrapper {
  overflow: hidden;
  margin-right: 20px;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(1) .title-word {
  animation-delay: 0s;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(2) .title-word {
  animation-delay: 0.15s;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(3) .title-word {
  animation-delay: 0.3s;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(4) .title-word {
  animation-delay: 0.45s;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(5) .title-word {
  animation-delay: 1s;
}
.bubble_container h1 .title-word-wrapper:nth-of-type(6) .title-word {
  animation-delay: 1.15s;
}
@keyframes slideUp {
  0% {
    transform: translateY(400%);
  }
  100% {
    transform: translateY(0%);
  }
}

.bubble_container button {
  font-size: 15px;
  color: #4767c7;
  background-color: #fff;
  padding: 12px 50px 12px 24px;
  border: none;
  border-radius: 29px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.bubble_container button:hover {
  background-color: #d1ddff;
  color: #1f40a1;
}
.bubble_container button:hover > svg path {
  fill: #1f40a1;
}

.bubble_container button:active {
  background-color: #b0c5ff;
  color: #1f40a1;
}

.bubble_container button:active > svg path {
  fill: #1f40a1;
}

.bubble_container button > svg {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.slider-main {
  width: 670px;
  margin-bottom: 18px;
  line-height: 136%;
  text-align: center;
}

.slider-main .swiper-pagination {
  position: relative;
  bottom: -4px !important;
}

.main-page-bottom {
  padding-top: 18px;
}

.instructions {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  width: 100%;
}

.instructions-background {
  width: 60.18%;
  border-radius: 30px;
  background-image: url('../../assets/images/main/instructions-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f8f6f6;
  background-size: contain;
}

.instructions-content {
  width: 38.19%;
  background-color: #4767c7;
  border-radius: 30px;
  padding: 39px 25px;
}

.instructions-content-title {
  font-size: 40px;
  margin-bottom: 25px;
  font-weight: 700;
}

.instructions-content-list {
  font-weight: 500;
  padding-left: 1rem;
  margin-bottom: 0;
}

.instructions-content-list li:not(:last-child) {
  margin-bottom: 8px;
}

.download-block {
  margin-bottom: 18px;
  padding: 50px 60px;
  background-color: #060607;
  border-radius: 30px;
  background-image: url('../../assets/images/main/download-bg.webp');
  background-repeat: no-repeat;
  background-position: bottom right -25%;
  background-size: 70%;
}
.download-block-content {
  width: 55%;
}
.download-block-content h2 {
  font-size: 40px;
  margin-bottom: 30px;
}
.download-block-content-description {
  font-weight: 500;
  margin-bottom: 56px;
}

.instructions-background {
  border-radius: 30px;
  width: 60.18%;
}

.change-image {
  animation: changeImage 1500ms ease-in;
}

@keyframes changeImage {
  0%{ opacity: 0;}
  100%{ opacity: 1;}
}

/* #myblob_big,
#myblob_small {
  border-radius: 50%;
  transform-origin: 50% 50%;
  backdrop-filter: blur(4px);
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.5);
  position: absolute;
  background-image: url('bubble2.png');
  background-repeat: no-repeat;
  background-size: contain;
} */
/*
#myblob_big {
  width: 220px;
  height: 220px;
  animation: move 95s ease-in-out infinite;
}

#myblob_small {
  width: 100px;
  height: 100px;
  animation: move1 70s ease-in-out infinite;
} */

/* #myblob_big span,
#myblob_small span {
  position: absolute;
  border-radius: 50%;
} */

/* #myblob_big span:nth-child(1),
#myblob_small span:nth-child(1) {
  background-color: rgba(44, 51, 221, 0.2);
  inset: 20px;
  filter: blur(4px);
}
#myblob_big span:nth-child(2),
#myblob_small span:nth-child(2) {
  background-color: #20263c10;
  inset: 3px;
  filter: blur(4px);
} */

/* @keyframes move {
  0% {
    transform: scale(0.9) rotate(0deg);
    left: 3%;
    top: 20%;
  }
  38% {
    transform: scale(0.8) rotate(160deg);
    left: 80%;
    top: 60%;
  }
  40% {
    transform: scale(0.8) rotate(160deg);
    left: 80%;
    top: 60%;
  }
  78% {
    transform: scale(1) rotate(-40deg);
    left: 30%;
    top: 0%;
  }
  80% {
    transform: scale(1) rotate(-40deg);
    left: 30%;
    top: 0%;
  }
  100% {
    transform: scale(0.9) rotate(0deg);
    left: 3%;
    top: 20%;
  }
}

@keyframes move1 {
  0% {
    transform: scale(1) rotate(0deg);
    left: 73%;
    bottom: 40%;
  }
  30% {
    transform: scale(0.8) rotate(-160deg);
    left: 50%;
    bottom: 80%;
  }
  32% {
    transform: scale(0.8) rotate(-160deg);
    left: 50%;
    bottom: 80%;
  }
  68% {
    transform: scale(1.3) rotate(40deg);
    left: 30%;
    bottom: 10%;
  }
  70% {
    transform: scale(1.3) rotate(40deg);
    left: 30%;
    bottom: 10%;
  }
  100% {
    transform: scale(1) rotate(0deg);
    left: 73%;
    bottom: 40%;
  }
} */

@media screen and (max-width: 3000px) {
  .bg-container_back,
  .bg-container_left,
  .bg-container_top {
    max-width: 1600px;
  }
}

@media screen and (max-width: 2500px) {
  .bg-container_back,
  .bg-container_left,
  .bg-container_top {
    left: 15%;
  }
}

@media screen and (max-width: 2000px) {
  .bg-container_back,
  .bg-container_left,
  .bg-container_top {
    left: 10%;
  }
}

@media screen and (max-width: 1760px) {
  .bubble_container {
    padding: 150px 0 100px;
  }
  .main-logo {
    margin-bottom: 30px;
  }
  .main-logo img {
    width: 85px;
    height: 82px;
  }
  .bubble_container h1 {
    font-size: 70px;
    width: 740px;
  }
  /* .bubble_container button {
    padding: 10px 50px 10px 28px;
  } */

  #myblob_big {
    width: 150px;
    height: 150px;
  }
  #myblob_small {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1600px) {
  .bg-container_back,
  .bg-container_left,
  .bg-container_top {
    left: 0%;
  }
}

@media screen and (max-width: 1480px) {
  .bubble_container h1 {
    font-size: 60px;
    width: 55%;
  }
  .download-block-content h2 {
    font-size: 35px;
    margin-bottom: 25px;
  }
  .download-block-content-description {
    margin-bottom: 50px;
  }
  .instructions-content-title {
    font-size: 35px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .bg-container_back,
  .bg-container_left,
  .bg-container_top {
    max-width: 1400px;
    left: 0%;
  }
  .main-video {
    width: 60%;
    height: 50%;
    /* height: auto; */
  }
}

@media (orientation: landscape) and (max-width: 1300px)  {
  .main-video {
    width: 60%;
    height: 80%;
    /* height: auto; */
  }
}

@media screen and (max-width: 1080px) {
  .main-page {
    font-size: 13px;
  }
  .main-logo {
    margin-bottom: 20px;
  }
  .main-logo img {
    width: 70px;
    height: 68px;
  }
  .bubble_container h1 {
    font-size: 50px;
    margin-bottom: 25px;
    width: 53%;
  }
  .bubble_container button {
    padding: 10px 44px 10px 20px;
  }
  .slider-main {
    margin-bottom: 25px;
  }
  .download-block {
    padding: 90px 50px;
    background-position: bottom right -5%;
    background-size: 55%;
  }
  .download-block-content h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .download-block-content-description {
    margin-bottom: 40px;
  }

  .instructions {
    flex-direction: column;
  }
  .instructions-background {
    width: 100%;
  }
  .instructions-content {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  .instructions-content-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .instructions-content-list {
    width: 70%;
  }
  .main-video {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  .download-block {
    background-position: bottom right -15%;
    background-size: 65%;
  }
}
@media screen and (max-width: 800px) {
  .bubble_container h1 {
    font-size: 40px;
  }
  .slider-main {
    width: 500px;
  }
  .instructions-content-list {
    width: 100%;
  }
  .instructions-content-title,
  .download-block-content h2 {
    font-size: 22px;
    margin-bottom: 18px;
  }
  .main-video {
    width: 90%;
  }
  @keyframes bgTop {
    0% {
      transform: translateX(0) scale(1);
      filter: brightness(1);
    }
    20% {
      transform: translateX(-5%) scale(1.1);
      filter: brightness(0.7);
    }
    60% {
      transform: translateX(10%) scale(1.3);
      filter: brightness(0.4);
    }
    100% {
      transform: translateX(0) scale(1);
      filter: brightness(1);
    }
  }
}

@media screen and (max-width: 740px) {
  .main-page {
    padding-top: 70px
  }
  .main-logo {
    display: none;
  }
  .bubble_container {
    padding: 93px 0 106px;
  }
  .bubble_container h1 {
    font-size: 34px;
  }
  .main-page-bottom {
    padding-top: 12px;
  }
  .download-block {
    padding: 30px 23px 455px;
    margin-bottom: 12px;
    background-size: contain;
    background-position: bottom right;
  }

  .download-block-content {
    width: 70%;
  }
  .download-block-content-description {
    margin-bottom: 28px;
  }
  .instructions {
    gap: 12px;
  }
  .instructions-content {
    padding: 35px 24px;
  }
  .instructions-content-list {
    line-height: 169%;
  }
  .instructions-content-list li:not(:last-child) {
    margin-bottom: 10px;
  }
  .main-video {
    width: 100%;

  }
}

@media screen and (max-width: 600px) {
  .bubble_container h1 {
    width: 65%;
  }
  .title-word-wrapper {
    margin-right: 10px;
  }
  .slider-main {
    width: 90%;
  }
  .download-block {
    background-size: cover;
    background-position: bottom left 54%;
  }
  .download-block-content {
    width: 85%;
  }
}

@media screen and (max-width: 450px) {
  .bubble_container h1 {
    width: 87%;
  }
  .slider-main {
    width: 100%;
  }
  .download-block-content {
    width: 100%;
  }
  .download-block {
    background-size: 175%;
  }
}
@media screen and (max-width: 400px) {
  .download-block {
    background-size: 190%;
  }
}
@media screen and (max-width: 350px) {
  .instructions-background {
    height: 280px;
  }
  .download-block {
    background-size: 220%;
  }
}
