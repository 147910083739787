.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mb-25 {
  margin-bottom: 25px;
}
.ml-25 {
  margin-left: 25px;
}
.position-fixed {
  position: fixed;
}

.block_container {
  border: 1px solid #434968;
  border-radius: 10px;
  padding: 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.block_container_double {
  padding: 0px !important;
}

.subtitle {
  color: #b6bee3;
}

.user-data {
  display: flex;
  align-items: center;
  gap: 30px;
}

.user-data img {
  border-radius: 50%;
  width: 151px;
  height: 151px;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.user-data .columns {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 500px));
  gap: 25px 100px;
  word-break: break-all;
}

.instructions-block_black {
  padding: 30px;
  width: 85%;
}

.instructions-block_yellow {
  background-color: #FFE500;
  color: #000000;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 8px 30px;
}

.pro-description {
  background-color: #44475b;
  padding: 30px;
  border-radius: 10px 10px 0 0;
}
.pro-payment {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 30px;
}

.pro-payment-items:not(:last-child) {
  margin-bottom: 4px;
}

.pro-description ul {
  padding-left: 1rem;
  margin-bottom: 0;
}

.pro-description ul li:not(:last-child) {
  margin-bottom: 15px;
}

.pro-payment-button-block {
  max-width: 360px;
  text-align: right;
  color: #b6bee3;
}
.button-pay {
  background: #597be1;
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  color: #ffffff;
}

@media screen and (max-width: 920px) {
  .block_container {
    padding: 20px 18px;
  }
  .user-data img {
    width: 100px;
    height: 100px;
  }
  .user-data .columns {
    gap: 20px 30px;
  }
  .pro-payment {
    flex-direction: column;
  }
  .pro-payment-button-block {
    text-align: left;
  }
}

@media screen and (max-width: 740px) {
  .user-data {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .user-data img {
    width: 90px;
    height: 90px;
    align-self: center;
  }
  .user-data .columns div:nth-child(1) {
    grid-area: el1;
  }
  .user-data .columns div:nth-child(2) {
    grid-area: el2;
  }
  .user-data .columns div:nth-child(3) {
    grid-area: el3;
  }
  .user-data .columns div:nth-child(4) {
    grid-area: el4;
  }
  .user-data .columns {
    gap: 18px;
    grid-template-areas:
      'el1'
      'el3'
      'el2'
      'el4';
  }

  .instructions-block_black {
    width: 100%;
    padding: 20px 18px 30px;
  }

  .instructions-block_yellow {
    padding: 11px 18px;
    align-items: flex-start;
  }

  .pro-description,
  .pro-payment {
    padding: 20px 18px;
  }
}
