.ua-container {
  padding-top: 83px;
  font-size: 15px;
}

.ua-container h1 {
  font-size: 25px;
  margin-bottom: 18px;
}

.ua-container a {
  color: #ffffff !important;
  text-decoration: underline !important;
}

@media screen and (max-width: 740px) {
  .ua-container {
    padding-top: 87px;
  }
  .ua-container h1 {
    font-size: 22px;
    margin-bottom: 16px;
  }
}