.button-lang {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  padding: 8px 29px 8px 16px;
  border-radius: 6px;
  margin-top: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 66px;
}

.button-lang-active {
  color: #799BFF;
  background-color: rgba(121, 155, 255, 0.2);
}
.white {
  color: #ffffff;
}

.button-lang-mobile {
  width: 100% !important;
  color: rgba(255, 255, 255, 0.5);
  background: #6d7580;
  border-radius: 100px;
  padding: 11px;
  width: 50%;
  border: none;
}

.button-lang-mobile-active {
  background: #799bff;
  border-radius: 100px;
  color: #ffffff;
}