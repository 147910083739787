.button-transparent {
  color: #799bff;
  padding: 10px 20px;
  border: 1px solid #799bff;
  border-radius: 100px;
  filter: drop-shadow(0px 2px 4px rgba(165, 163, 174, 0.3));
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  background-color: transparent;
  cursor: pointer;
}

.button-transparent:hover {
  background-color: rgba(89, 123, 225, 0.51);
  color: #fff;
}

.button-transparent-img {
  display: flex;
  align-items: center;
}
.button-transparent-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.button-transparent-active {
  background-color: #597BE1;
  color: #fff;
}
