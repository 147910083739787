.auth {
  min-height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-family: Manrope, Arial, Helvetica, sans-serif;
  background-color: #1a1b1d;
  position: relative;
}
.auth-to-main {
  color: #799bff;
  position: absolute;
  top: 37px;
  left: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.auth-form {
  width: 55%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-img:before {
  content: '';
  display: block;
  background-color: #1a1b1d;
  width: 60px;
  height: 100vh;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

.auth-img {
  width: 45%;
  height: 100vh;
  background-image: url('../../assets/images/auth-bg.webp');
  background-size: cover;
  background-position: center;
}

.auth-form > div {
  text-align: center;
}

.auth-form-container {
  width: 390px;
}

.auth-logo {
  margin-bottom: 20px;
  width: 105px;
  height: 102px;
}
.auth-form h1 {
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  margin-bottom: 16px;
}

.auth-form h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.login-google-apple-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.login-button {
  /* font-weight: 700; */
  border: 1px solid #ffffff;
  /* border-radius: 10px; */
  /* background-color: transparent; */
  /* color: #ffffff; */
  /* width: 100%; */
  /* padding: 7px; */
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 12px; */
  cursor: pointer;
}

/* .login-button:not(:last-child) {
  margin-bottom: 14px;
} */

.auth-login-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
}
.auth-login-form label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}
.auth-login-form label:not(:last-of-type) {
  margin-bottom: 15px;
}
.auth-input {
  border-radius: 20px;
  background-color: rgba(87, 93, 100, 0.9);
  border: 1px solid rgba(87, 93, 100, 0.9);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 14px;
  width: 100%;
}

.auth-input:-webkit-autofill,
.auth-input:-webkit-autofill:hover,
.auth-input:-webkit-autofill:focus,
.auth-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 25px rgba(87, 93, 100, 0.9) inset !important;
  -webkit-text-fill-color: #fff !important;
}

.auth-input_error {
  border: 1px solid #ed1649;
}

.auth-input_error-message {
  margin-top: 10px;
  font-size: 12px;
  color: #ed1649;
}

.login-button-password {
  border-radius: 20px;
  border: none;
  background-color: #799bff;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.43px;
  padding: 10px 65px;
  width: max-content;
  align-self: center;
  margin-top: 20px;
  cursor: pointer;
}
.auth-link-forgot {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #799bff;
  align-self: flex-end;
  margin-top: 6px;
}
.auth-link-restore {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #4767C7;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}


@media (max-width: 1750px) {
  .auth-form {
    width: 50%;
  }
  .auth-img {
    width: 50%;
  }
}

@media (max-width: 1080px) {
  .auth {
    flex-direction: column-reverse;
    justify-content: flex-end;
    min-height: 100vh;
    height: 100%;
    overflow-y: scroll;
  }
  .auth-form {
    /* height: 70%; */
    height: auto;
    width: 100%;
    padding: 0 20px;
    align-items: flex-start;
    margin-top: 66px;
  }
  .auth-logo {
    margin-bottom: 12px;
    width: 66px;
    height: 65px;
  }
  .auth-img {
    height: 450px;
    /* height: 30%; */
    background-image: url('../../assets/images/auth-bg-mobile.webp');
    width: 100%;
    background-position: 50% 30%;
  }
  .auth-img:before {
    display: none;
  }
  .auth-to-main {
    top: 475px;
    left: 16px;
  }
  .auth-login-form {
    margin-bottom: 30px;
  }
}
@media (max-width: 820px) {
  .auth-img {
    height: 350px;
  }
  .auth-to-main {
    top: 375px;
  }
}

@media (max-width: 500px) {
  .auth-img {
    height: 250px;
  }
  .auth-to-main {
    top: 275px;
  }
}
@media (max-width: 430px) {
  .auth-img {
    /* background-image: url('../../assets/images/auth-bg-mobile.webp'); */
  }
  .auth-form h1 {
    font-size: 20px;
    margin-bottom: 14px;
  }
  .auth-form h2 {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .auth-form-container {
    width: 100%;
  }
  .auth-form {
    margin-top: 35px;
  }

  .auth-logo {
    width: 46px;
    height: 45px;
    margin-bottom: 5px;
  }
  .auth-login-form {
    margin-bottom: 20px;
  }
  .login-button {
    width: 44px;
    height: 44px;
  }
  .auth-input {
    font-size: 12px;
    padding: 8px 14px;
  }
  .auth-login-form label:not(:last-of-type) {
    margin-bottom: 10px;
  }
  .auth-img {
    height: 200px;
  }
  .auth-to-main {
    top: 215px;
  }
}
