.services-container {
  padding-top: 94px;
  font-size: 15px;
}

.services-nav-container {
  display: flex;
}

.services-nav-container .button-transparent {
  margin-left: 10px;
  height: fit-content;
  line-height: 1.2;
}

.services-title-block {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
}

.services-title-block-capsule {
  background-image: url('../../assets/images/services/services-bg.webp');
  padding: 70px 30px 30px;
}
.services-title-block-content {
  width: 69%;
  text-align: center;
  margin: 30px 0;
}

.services-more-button {
  border-color: #FFF;
  color: #fff;
  margin-top: 8px
}

.services-title-block-avatar .services-title-block-content {
  width: 67%;
}

.services-title-block-content h1 {
  margin: 0 auto 17px;
  font-size: 40px;
  line-height: 125%;
  text-transform: uppercase;
}

.services-title-block-steps {
  list-style: none;
  display: flex;
  gap: 19px;
  margin-bottom: 66px;
  padding: 0;
}

.services-title-block-steps li {
  padding: 8px 20px;
  border-radius: 100px;
  border: 1px solid #ffffff;
  position: relative;
  white-space: nowrap;
}

.services-title-block-steps li:not(:last-child)::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -4px;
}

.services-title-block-steps li:not(:last-child)::before {
  content: '';
  display: block;
  width: 19px;
  height: 1px;
  border: 1px dashed #ffffff;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -19px;
}

.services-title-block-link {
  text-decoration: underline;
  color: #ffffff;
}

.services-order {
  display: flex;
  background-color: #4767c7;
  border-radius: 30px;
}

.services-order-white {
  background-color: #ffffff;
  color: #000000;
  padding: 45px 60px 54px 67px;
  border-radius: 30px;
  width: 56%;
}
.services-order-white-top {
  font-weight: 500;
  line-height: 20.5px;
  margin-bottom: 30px;
}
.services-order-white-top h2 {
  margin-bottom: 15px;
  width: 80%;
  font-weight: 700;
  line-height: 106%;
}
.services-order-white-top h2::after {
  content: '';
  display: block;
  width: 314px;
  height: 7px;
  background-image: url('../../assets/images/services/timeCapsuleLine.svg');
  background-repeat: no-repeat;
  margin-top: 7px;
  background-position: center;
}
.services-order-black {
  background-color: #1a1b1d;
  color: #ffffff;
  padding: 35px 40px 35px 24px;
  border-radius: 30px;
  font-weight: 500;
  position: relative;
}
.services-order-black::after {
  content: '';
  display: block;
  position: absolute;
  width: 21px;
  height: 45px;
  left: 0px;
  bottom: -10px;
  background: #1a1b1d;
  border-top-right-radius: 10px;
  transform: matrix(-1, 2, 0, -1, 0, -17);
}

.services-order-black h2 {
  margin-bottom: 25px;
}
.services-order-black-price {
  color: #beceff;
  margin-bottom: 12px;
  font-weight: 600;
}
.services-order-blue {
  padding: 100px 40px 106px 38px;
  width: 44%;
}
.services-order-form {
  margin-top: 35px;
  margin-bottom: 25px;
}
.input {
  background-color: #5d7ee3;
  border-radius: 100px;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.7);
  padding: 11px 14px;
  border: 1px solid #5d7ee3;
}
.input:focus {
  border: 1px solid #ffffff;
}
.input_error {
  border: 1px solid #ff6a6a;
}
.form-floating {
  position: relative;
}
.form-floating:not(:last-of-type) {
  margin-bottom: 30px;
}

.form_floating_input::placeholder {
  opacity: 0;
}
.form_floating_input:focus ~ label,
.form_floating_input:not(:placeholder-shown) ~ .form-floating_label,
.form-select ~ .form-floating_label {
  opacity: 1;
  transform: translateY(-1.9rem) translateX(-0.8rem);
}
.form-floating_label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 200%;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 4px 14px;
  pointer-events: none;
  border: 1px solid transparent;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.services-order-form-button {
  background-color: #ffffff;
  border-radius: 100px;
  color: #4767c7;
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  margin-top: 25px;
}
.services-order-form-button:hover {
  background-color: #d1ddff;
  color: #1f40a1;
}
.services-order-form-button:active {
  background-color: #b0c5ff;
  color: #1f40a1;
}
.services-order-phones {
  display: flex;
  align-items: center;
  gap: 14px;
}

.services-order-phones a {
  color: #ffffff;
}

.services-order-phones span {
  color: rgba(255, 255, 255, 0.5);
}

.services-title-block-avatar {
  background-image: url('../../assets/images/services/avatarTitleBg.webp');
  background-position: center;
  padding: 163px 30px 146px;
}
.services-section-avatar {
  border-radius: 30px;
  display: flex;
  margin-bottom: 18px;
}

.services-section-avatar-gray {
  background-color: #e6e6e6;
}
.services-section-avatar-blue {
  background-color: #4767c7;
}
.services-section-avatar-content {
  background-color: #f8f6f6;
  color: #000000;
  padding: 75px 39px;
  width: 71%;
  border-radius: 30px;
}

.services-section-avatar-content-vers {
  margin-bottom: 14px;
}
.services-section-avatar-content .download-block-content-links {
  margin-top: 30px;
}
.services-section-avatar-content-narrow {
  width: 62%;
}

.services-section-avatar h2 {
  margin-bottom: 30px;
}

.services-section-avatar p {
  font-weight: 500;
}
.services-section-avatar p:last-child {
  margin-bottom: 0;
}
.services-section-avatar-content-img {
  margin: auto;
}
.services-section-avatar-content-underline,
.services-section-avatar-content-underline-long,
.services-section-avatar-content-underline-arrow {
  display: inline-block;
  position: relative;
}
.services-section-avatar-content-underline::before,
.services-section-avatar-content-underline-long::before,
.services-section-avatar-content-underline-arrow::before {
  content: '';
  width: 100%;
  height: 4px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.services-section-avatar-content-underline::before {
  background-image: url('../../assets/images/services/underline.svg');
}

.services-section-avatar-content-underline-long::before {
  background-image: url('../../assets/images/services/underline-long.svg');
}

.services-section-avatar-content-underline-arrow::before {
  bottom: -4px;
  height: 8px;
  background-image: url('../../assets/images/services/timeCapsuleLine.svg');
}

@media screen and (max-width: 1480px) {
  .services-title-block img {
    width: 300px;
    height: auto;
  }
  .services-order-white {
    padding: 35px 60px 40px 50px;
  }
  .services-title-block-content {
    width: 60%;
    margin: 25px 0;
  }
  .services-title-block-content h1 {
    font-size: 35px;
  }
  .services-order-black h2 {
    margin-bottom: 20px;
  }
  .services-order-white-top h2::after {
    width: 275px;
  }
  .services-order-blue {
    padding-top: 85px;
  }
  .services-order-form {
    margin-top: 40px;
  }
  .services-section-avatar h2 {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1080px) {
  .services-container {
    padding-top: 83px;
    font-size: 13px;
  }
  .services-title-block {
    border-radius: 0;
  }
  .services-title-block-content {
    width: 80%;
  }
  .services-title-block-steps {
    gap: 13px;
  }
  .services-title-block-steps li:not(:last-child)::before {
    right: -14px;
    width: 11px;
  }
  .services-order-white {
    padding: 35px 40px 40px 40px;
  }
  .services-order-white-top h2::after {
    width: 240px;
  }
  .services-order-form {
    margin-top: 35px;
  }
  .services-title-block-avatar {
    padding: 120px 30px 125px;
  }
}

@media screen and (max-width: 900px) {
  .services-order {
    flex-direction: column;
  }
  .services-order-white,
  .services-order-blue {
    width: 100%;
  }
  .services-order-blue {
    padding: 40px;
  }
  .services-order-white-top h2 {
    width: 50%;
  }

  .services-section-avatar {
    flex-direction: column;
  }
  .services-section-avatar-blue {
    flex-direction: column-reverse;
  }
  .services-section-avatar-content,
  .services-section-avatar-content-narrow {
    width: 100%;
  }
  .services-section-avatar-content {
    padding: 40px 30px;
  }
  .services-section-avatar-content-img {
    padding: 30px;
  }
  .services-section-avatar-content-img img {
    width: 100%;
    height: auto;
  }
  .services-section-avatar h2 {
    margin-bottom: 20px;
  }
  .services-title-block-avatar .services-title-block-content {
    width: 76%;
  }
  .services-title-block-avatar {
    padding: 85px 30px 100px;
  }
}

@media screen and (max-width: 740px) {
  .services-container {
    padding-top: 67px;
  }
  .services-title-block {
    margin-bottom: 12px;
    border-top: 1px solid #3f4043;
    margin-right: -15px;
    margin-left: -15px;
  }
  .services-title-block-capsule {
    padding: 50px 20px 25px;
  }
  .services-title-block-content {
    width: 95%;
  }
  .services-more-button {
    padding: 6px 16px;
    font-size: 14px;
  }
  .services-title-block-content h1 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  .services-title-block-steps {
    gap: 4px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
  }
  .services-title-block-steps li:not(:last-child)::before {
    display: none;
  }

  .services-title-block-steps li:not(:last-child)::after {
    display: none;
  }
  .services-order-white {
    padding: 30px 23px 40px;
  }
  .services-order-white-top {
    margin-bottom: 21px;
  }
  .services-order-white-top h2 {
    width: 48%;
  }
  .services-order-white-top h2::after {
    width: 200px;
  }
  .services-order-black {
    padding: 35px 24px;
  }
  .services-order-black-price {
    margin-bottom: 10px;
  }
  .services-order-blue {
    padding: 30px 23px 35px;
  }
  .services-order-form {
    margin-top: 30px;
  }
  .services-section-avatar {
    margin-bottom: 12px;
  }
  .services-section-avatar-content,
  .services-section-avatar-content-img {
    padding: 30px 23px;
  }
  .services-section-avatar h2 {
    margin-bottom: 18px;
  }

  .services-title-block-avatar .services-title-block-content {
    width: 85%;
  }

  .services-title-block-avatar .services-title-block-content h1 {
    margin-bottom: 25px;
  }
  .services-section-avatar-content .download-block-content-links {
    margin-top: 15px;
  }
  .services-section-avatar-content-vers {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 540px) {
  .services-title-block img {
    width: 100%;
  }
  .services-title-block-content {
    width: 100%;
  }
  .services-order-white-top h2 {
    width: 85%;
  }
}

@media screen and (max-width: 480px) {
  .services-title-block-avatar .services-title-block-content {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .services-title-block-avatar {
    background-image: url('../../assets/images/services/avatarTitleBg-mob.webp');
    padding: 180px 30px 180px;
  }
  .services-title-block-steps li {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 380px) {
  .services-title-block-avatar .services-title-block-content {
    width: 100%;
  }
  .services-title-block-avatar .services-title-block-content h1 {
    font-size: 30px;
  }
}
