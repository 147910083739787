* {
  font-family: Manrope, Arial, Helvetica, sans-serif;
}
.layout-main {
  padding-top: 93px;
  padding-bottom: 40px;
  overflow-y: hidden;
}

@media screen and (max-width: 740px) {
  .layout-main {
    padding-top: 120px;
    padding-bottom: 20px;
  }
}
