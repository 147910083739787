.download-block-content-links {
  display: flex;
  gap: 18px;
}
.download-block-content-links a {
  display: flex;
}

@media screen and (max-width: 1480px) {
  .download-block-content-links a svg {
    width: 180px;
    height: auto;
  }
}

@media screen and (max-width: 1080px) {
  .download-block-content-links a svg {
    width: 155px;
  }
}

@media screen and (max-width: 420px) {
  .download-block-content-links {
    justify-content: space-between;
    gap: 5px;
  }
}

@media screen and (max-width: 375px) {
  .download-block-content-links,
  .download-block-content-links a svg {
    width: 100%;
  }
}
