.lang-modal {
  position: relative;
  width: 328px;
}

.close-button {
  position: absolute;
  background: transparent;
  border: none;
  right: 6px;
  top: 6px;
  padding: 0;
  padding: 10px;
  cursor: pointer;
}

.modal-heading {
  color: #f2efeb;
  justify-content: center;
  margin-bottom: 16px;
}
.modal-buttons-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 14px;
}

.lang-block-desktop {
  position:absolute;
  padding: 0 8px 4px;
  display: flex;
  flex-direction: column;
  background-color: #35393D;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 3;
}

.lang-button {
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px 10px 8px 24px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.lang-button span {
  display: block;
  width: 22px;
}
.lang-button-active {
  background-color: #35393D;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.lang-button-active img {
  transform: rotate(180deg);
}