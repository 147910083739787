
.video-block {
    height: 520px;
    border-radius: 30px;
    padding: 100px 80px;
    margin-bottom: 20px;
    position: relative;
}

.video-block-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Черный оверлей с 50% прозрачностью */
    z-index: 2;
    border-radius: 30px;
    opacity: 0.5;
}

.video-block-video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: none;
    z-index: 0;
    top: 0;
    left: 0;
    border-radius: 30px;
}

.video-block-title {
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 60px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-family: sans-serif;
    z-index: 3;
    position: relative;
}

.video-block-subtitle {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 40px;
    z-index: 3;
    position: relative;
}

.video-block-button {
    z-index: 3;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    height: 48px;
    background: #799BFF;
    border-radius: 100px;
    border: none;
    cursor: pointer;
    fill: #FFFFFF;
    /* Text */

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.43px;
    color: #FFFFFF;
}

.video-block-button-icon {
    fill: #FFFFFF;
}

.video-block-button:hover {
    background-color: #FFFFFF;
    color: #799BFF;
}

.video-block-button:hover > .video-block-button-icon {
    fill: #799BFF;
}


@media screen and (max-width: 500px) {
    .video-block {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 112px;
        height: auto;
    }
    .video-block-video-container {
        position: relative;
        background: transparent;
        opacity: initial;
    }

    .video-block-video {
        margin-bottom: 40px;
        height: 53vw;
        position: relative;
        object-fit: cover;
    }

    .video-block-title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
    }

    .video-block-subtitle {
        font-size: 24px;
        line-height: 25px;
    }

    .video-block-button {
        width: fit-content;
    }
}
