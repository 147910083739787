.intro-container {
    padding-top: 94px;
    font-size: 15px;
}

/*-----------------------------------------IMAGINE*/
.intro-imagine {
    border-radius: 30px;
    background-image: url("../../assets/images/intro/imagine-background.png");
    background-size: 100% 100%;
    padding: 80px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
}

.intro-imagine-title {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #1A1B1D;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.intro-imagine-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(74, 46, 13, 0.25);
    border-radius: 24px;
}

.intro-imagine-block:first-of-type {
    margin-bottom: 40px;
    padding-left: 80px;
}

.intro-imagine-block-text {
    padding-top: 12px;
    padding-bottom: 12px;
}

.intro-imagine-block-img {
    min-height: 300px;
    height: 100%;
}

.intro-imagine-block:first-of-type > .intro-imagine-block-img {
    margin-left: 40px;
}

.intro-imagine-block:last-of-type {
    padding-right: 80px;
    margin-bottom: 40px;
}

.intro-imagine-block:last-of-type > .intro-imagine-block-img {
    margin-right: 40px;
}

.intro-imagine-block-title {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1A1B1D;
    margin-bottom: 20px;
}

.intro-imagine-block-subtitle {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #1A1B1D;
}

.intro-imagine-footer-text {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #1A1B1D;
    padding-right: 10%;
    padding-left: 10%;
}

/*---------------HOW IT WORKS*/

.intro-howItWorks {
    padding: 80px;
    background: #EAEFFF;
    border-radius: 30px;
    margin-bottom: 100px;
}

.intro-howItWorks-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    max-width: 100%;
}

.intro-howItWorks-title_small {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #1A1B1D;
    margin-bottom: 8px;
}

.intro-howItWorks-title_big {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #1A1B1D;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.intro-howItWorks-text {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #1A1B1D;
    margin-bottom: 40px;
}

.intro-howItWorks-block {
    padding: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(36, 47, 79, 0.25);
    border-radius: 24px;
    display: flex;
    margin-bottom: 40px;
}

.intro-howItWorks-block-icon {
    min-width: 80px;
    min-height: 80px;
    margin-right: 40px;
}

.intro-howItWorks-block .intro-howItWorks-text {
    margin: 0
}

.intro-howItWorks-block .intro-howItWorks-text > span {
    font-weight: 600;
}

/*
---------------FIRST AVATAR
*/

.intro-firstAvatar-title {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    max-width: 876px;
    margin: 0 auto 40px auto;
    text-transform: uppercase;
}

.intro-firstAvatar-subtitle {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto 90px auto;
    max-width: 778px;
}

.intro-firstAvatar-block {
    border-radius: 30px;
    background-image: url("../../assets/images/intro/firstAvatar-background.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 80px;
}

.intro-firstAvatar-block-photo {
    position: relative;
    max-width: 800px;
    width: 70%;
    top: -52px;
    left: 50%;
    transform: translate(-50%, 0);
}

.intro-firstAvatar-block-text {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #1A1B1D;
    margin-bottom: 80px;
}
.intro-firstAvatar-promo {
    display: flex;
    position: relative;
}
.intro-firstAvatar-promo-phone-img {
    bottom: -130px;
    position: relative;
    margin-right: 40px;
}
.intro-firstAvatar-promo-block {
    padding-top: 80px;
    max-width: 430px;
}
.intro-firstAvatar-promo-text {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: #1A1B1D;
    margin-bottom: 20px;
}
.intro-firstAvatar-promo-price {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 140%;
    color: #1A1B1D;
    margin-bottom: 40px;
}

.intro-firstAvatar .download-block-content-links {
    flex-direction: column;
    margin-bottom: 40px;
}

.intro-firstAvatar-promo-ps {
    font-family: 'Courier New';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #1A1B1D;
}

@media screen and (max-width: 840px) {
    .intro-imagine {
        flex-direction: column;
        padding: 40px 12px;
        margin-bottom: 12px;
    }

    .intro-imagine-title {
        margin-bottom: 20px;
    }

    .intro-imagine-block {
        flex-direction: column;
        border-radius: 35px;
    }

    .intro-imagine-block:first-of-type, .intro-imagine-block:last-of-type {
        padding: 0;
    }

    .intro-imagine-block:first-of-type > .intro-imagine-block-img, .intro-imagine-block:last-of-type > .intro-imagine-block-img {
        margin: 0;
        width: 100%;
    }

    .intro-imagine-block:first-of-type {
        flex-direction: column-reverse;
    }

    .intro-imagine-block-text {
        padding: 20px;
    }

    .intro-imagine-block-title {
        font-size: 16px;
        line-height: 140%;
    }

    .intro-imagine-block-subtitle {
        font-size: 16px;
        line-height: 140%;
    }

    .intro-imagine-footer-text {

        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        padding: 0;
    }


    .intro-howItWorks {
        padding: 40px 12px;
        border-radius: 30px;
    }

    .intro-howItWorks-img {
        width: 100%;
        margin-bottom: 20px;
    }

    .intro-howItWorks-title_small {
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 8px;
    }

    .intro-howItWorks-title_big {
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .intro-howItWorks-text {
        font-size: 16px;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .intro-howItWorks-block {
        flex-direction: column;
        padding: 20px;
        margin-bottom: 20px;
    }

    .intro-howItWorks-block-icon {
        max-width: 40px;
        max-height:  40px;
        min-width: 40px;
        min-height:  40px;
        margin-bottom: 20px;
    }


    /*-------------FIRST AVATAR*/

    .intro-firstAvatar-title {
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .intro-firstAvatar-subtitle {
        margin-bottom: 80px;

        font-size: 16px;
        line-height: 140%;
    }

    .intro-firstAvatar-block {
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 40px;
    }

    .intro-firstAvatar-block-photo {
        width: 92%;
    }

    .intro-firstAvatar-block-text {
        font-size: 16px;
        line-height: 140%;
        top: -30px;
        position: relative;
        margin-bottom: 0;
    }
    .intro-firstAvatar-promo {
        flex-direction: column;
    }
    .intro-firstAvatar-promo-phone-img {
        bottom: 0;
        max-width: 200px;
        display: block;
        margin: 0 auto 20px auto;
    }
    .intro-firstAvatar-promo-block {
        padding-top: 0;
        max-width: 800px;
    }
    .intro-firstAvatar-promo-text {
        font-size: 14px;
        line-height: 23px;
        margin-bottom: 20px;
    }
    .intro-firstAvatar-promo-price {
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 20px;
    }

    .intro-firstAvatar .download-block-content-links {
        margin-bottom: 20px;
    }

    .intro-firstAvatar .download-block-content-links svg {
        width: 200px
    }

    .intro-firstAvatar-promo-ps {
        font-size: 14px;
        line-height: 23px;
    }
}
