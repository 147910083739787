.main-error {
  font-family: Manrope, Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 0.6);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.main-error h1 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  margin: 60px 0 25px;
}

.main-error p {
  max-width: 950px;
  text-align: center;
  font-size: 25px;
  line-height: 38px;
}


@media screen and (max-width: 1080px) {
  .main-error h1 {
    font-size: 20px;
    line-height: 28px;
    margin: 35px 0 20px;
  }

  .main-error p {
    font-size: 18px;
    line-height: 26px;
    width: 90%;
  }

  .main-error svg {
    width: 300px;
  }
}
