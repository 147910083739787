.story-main {
    padding-top: 94px;
    font-size: 15px;
}
.story-container {
    border-radius: 30px;
    background-image: url("../../assets/images/services/connection-background/top.png"),
    url("../../assets/images/services/connection-background/bottom.png");
    background-position: top, bottom;
    background-size: 100% 98%, 100% 2.15%;
    background-repeat: no-repeat;
    position: relative;
    background-color: #F8F6F6;
    /*height: 7000px*/
    padding: 50px 40px 65px 40px;
}

.story-container p {
    font-family: "Courier New";
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: #1A1B1D;
    margin-bottom: 0;
}

.story-header {
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #1A1B1D;
    padding: 0 32px;
    margin-bottom: 50px;
}

.story-content {
    margin-bottom: 120px;
}

.story-content > p {
    margin-bottom: 30px;
}

.story-message-img {
    margin-bottom: 140px;
    display: flex;
    justify-content: center;
}

.story-message-img img {
    width: 506px;
}

.story-post-scriptum {
    display: flex;
    flex-direction: row;
    white-space: pre-wrap;
    margin-bottom: 50px;
}
.story-post-scriptum > p:first-of-type {
    margin-right: 10px;
}

.download-block-content-links {
    justify-content: center;
    margin-bottom: 65px;
}

.story-app-info {
    background-color: #E6E6E6;
    padding: 35px 23px 28px;
    margin-bottom: 67px;
}

.story-img {
    margin: 0 auto 80px auto;
    max-width: 522px;
}

.story-img img {
    display: block;
    width: 100%;
    margin-bottom: 30px;
}

.story-img:last-of-type {
    margin-bottom: 0;
}

@media screen and (max-width: 500px) {
    .story-container {
        padding: 30px 20px;
    }
    .story-header {
        font-size: 22px;
        line-height: 24px;
        padding: 0;
        margin-bottom: 90px;
    }
    .story-content {
        margin-bottom: 125px;
    }
    .story-message-img {
        margin-bottom: 145px;
    }
    .story-message-img img {
        width: auto
    }
    .download-block-content-links {
        margin-bottom: 35px;
    }
    .story-app-info {
        padding: 14px;
        margin-bottom: 45px;
    }
    .story-img {
        width: 100%;
    }
}

