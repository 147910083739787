.status-item {
  color: #c4c4c4;
  padding: 5px 10px;
  background: rgba(115, 103, 240, 0.16);
  border-radius: 4px;
  width: max-content;
}
.status-item-green {
  color: #74D77E;
}
.status-item-yellow {
  color: #FFE279;
}
.status-item-red {
  color: #F27852
}
.table-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.6);
  height: calc(100vh - 67px);
  gap: 60px;
}
.table-empty-text {
  font-size: 18px;
  text-align: center;
}
@media screen and (max-width: 920px) {
  .table-container {
    overflow-y: auto;
    width: max-content;
  }
}

@media screen and (max-width: 740px) {
  .table-empty {
    height: calc(100vh - 117px);
    gap: 35px;
    height: 100%;
    padding-top: 150px;
  }
  .table-empty svg {
    width: 105px;
    height: 105px;
  }
  .table-empty-text {
    font-size: 16px;
    width: 90%;
  }
}
