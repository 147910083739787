.services-nav {
  margin-bottom: 30px;
  display: flex;
}

.services-nav-item {
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 25px;
  white-space: nowrap;
}

.services-nav-item:hover {
  color: rgba(255, 255, 255, 0.6);
}

.services-nav-item-connection {
  display: none;
}

.services-nav-item.active {
  background-color: #597BE1;
  color:#FFFFFF;
}

@media screen and (max-width: 1080px) {
  .services-nav-item {
    font-size: 14px;
  }
}

@media screen and (max-width: 740px) {
  .services-nav-item-connection {
    display: inline-block;
  }
  .services-connection-button {
    display: none;
  }
  .services-nav  {
    width: 100%;
    gap: 30px;
    margin-bottom: 0;
    overflow: auto;
  }
  .services-nav-item {
    font-size: 12px;
    line-height: 20px;
    justify-content: center;
    width: 50%;
    padding: 0 5px 15px;
    text-align: center;
  }
  .services-nav-item svg {
    display: none;
  }
  .services-nav-item.active {
    background-color: transparent;
    color: #799BFF;
    position: relative;
  }
  .services-nav-item.active::after {
    content: '';
    background-color: #799bff;
    height: 4px;
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
