.main-footer {
  padding: 55px 0 0;
  color: #ffffff;
}

.main-footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 65px;
  margin-bottom: 25px;
}

.main-footer-links-item {
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  max-width: 20%;
  width: max-content;
}
.main-footer-links-item:nth-child(1) {
  text-align: right;
}
.main-footer-links-item:nth-child(2) {
  text-align: center;
}

.main-footer-links-item_underline {
  text-decoration: underline;
}

.main-footer-links-item.active {
  color: #799bff
}

.main-footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  margin-bottom: 23px;
}

.main-footer-bottom {
  background-color: #101011;
}

.main-footer-bottom .container {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
}

@media screen and (max-width: 1100px) {
  .main-footer {
    padding: 45px 0 0;
  }
  .main-footer-links {
    flex-direction: column;
    gap: 18px;
  }
  .main-footer-links-item {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
  .main-footer-links-item:nth-child(1) {
    order: 2;
    text-align: center;
  }
  .main-footer-links-item:nth-child(2) {
    order: 1;
  }
  .main-footer-links-item:nth-child(3) {
    order: 3;
  }
  .main-footer-socials a img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 450px) {
  .main-footer-links-item,
  .main-footer-links-item:nth-child(1),
  .main-footer-links-item:nth-child(2) {
    text-align: left;
  }
  .main-footer-socials {
    justify-content: flex-start;
  }
  .main-footer-bottom .container {
    text-align: left;
  }
}
