.main-header {
  padding: 6px 0;
  border-bottom: 1px solid #3f4043;
  position: fixed;
  width: 100%;
  background-color: #1a1b1d;
  z-index: 4;
}
.main-header > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.burger-button {
  display: none;
}
.main-header nav {
  display: flex;
  gap: 60px;
}
.main-header-right {
  display: flex;
  align-items: center;
}
.main-header .nav-item {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 12px;
}

.main-header .nav-item:hover {
  color: #ffffff;
}

.main-header .nav-item.active {
  color: #ffffff;
  position: relative;
}
.main-header .nav-item.active:after {
  content: '';
  background-color: #799bff;
  height: 4px;
  width: 48px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%);
}

.main-header .burger-menu {
  top: 33px;
}

.main-header-mobile-top {
  width: 185px;
}
.main-header-mobile-top .main-header-logo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.main-header-nav-mob-profile.active {
  display: none;
}

@media screen and (max-width: 920px) {
  .main-header nav {
    gap: 30px;
  }
  .main-header-mobile-top {
    width: 150px;
  }
}

@media screen and (max-width: 740px) {
  .main-header {
    padding: 15px 0;
  }
  .main-header > .container {
    flex-direction: column;
  }
  .main-header-logo-text {
    display: none;
  }
  .burger-button {
    display: block;
    position: absolute;
    right: 0px;
    border: none;
    background-color: transparent;
  }
  .main-header-right {
    display: none;
  }
  .main-header-mobile-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    height: 37px;
  }
  .main-header .nav-item.nav-item-mob-underline {
    font-weight: 600;
  }
  .main-header .nav-item.nav-item-mob-underline.active {
    color: #799bff;
  }
  .main-header .nav-item.nav-item-mob-underline.active:after {
    width: 100%;
    bottom: -15px;
  }
  .main-header nav {
    width: 100%;
  }
  .main-header nav .nav-item.nav-item-mob-underline {
    font-size: 12px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    width: 50%;
  }

  .border-mob-hidden.container nav {
    position: relative;
  }
  .main-header-nav-mob-hidden::after {
    display: none;
  }
  .main-header-nav-mob-hidden:not(.active) {
    display: none !important;
  }
  .main-header .main-header-nav-mob-hidden.active,
  .main-header-nav-mob-profile.active {
    position: absolute;
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .main-header .main-header-nav-mob-hidden.active {
    top: 20px;
  }
  .border-mob-hidden {
    border: none !important;
  }
  .border-mob-hidden .main-header-mobile-top {
    margin-bottom: 0px;
  }
}
