main {
  min-height: calc(100vh - 115px - 30px);
  color: #fff;
}
.fade-in {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tree-modal {
  position: relative;
}
.tree_empty {
  margin-top: 80px;
}
.root_tree {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1b1d;
}
.root__header {
  position: relative;
  background: #000;
  border-radius: 0 0 36px 0;
}
.root__header::after {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 50px;
  height: 50px;
  border-top-left-radius: 36px;
  box-shadow: -15px -15px 0 0 black;
}
.root__main {
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  border-radius: 36px 0 0 0;
}
.root__footer {
  height: 30px;
  padding: 5px 0;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  background: black;
}
.root__footer a {
  color: #fff;
  opacity: 0.5;
}
.root__footer a:hover {
  color: #799bff;
}

.modal_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.user-slider .swiper {
  padding: 0 8px !important;
  user-select: none;
}

.swiper-button-disabled {
  color: #9d9d9d !important;
  cursor: auto !important;
  pointer-events: all !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffffff !important;
}

.user-slider .swiper-button-prev {
  left: -8px !important;
}
.user-slider .swiper-button-next {
  right: -8px !important;
}
.user-slider .swiper-button-prev:after,
.user-slider .swiper-button-next:after {
  font-size: 18px !important;
  font-weight: 700;
}
.modal-swiper {
  height: 100% !important;
}
.modal-swiper > .swiper-pagination {
  display: block;
  bottom: 10px !important;
}

.modal-swiper > .swiper-wrapper {
  height: 95%;
  display: flex;
  align-items: center;
}

.modal-swiper .swiper-slide {
  cursor: auto;
}

.user {
  position: relative;
  padding: 35px;
  width: 510px;
  color: #fff;
  cursor: auto;
}
.user__avatar {
  display: block;
  width: 110px;
  height: 110px;
  margin: 0 auto 25px auto;
  overflow: hidden;
  border-radius: 50%;
  border: 2px white solid;
}
.user__avatar img {
  background: #575d64;
}

.user__content {
  font-size: 16px;
  line-height: 20px;
}
.user__name {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}
.user__info {
  margin: 0 0 38px 0;
  padding: 0;
  list-style: none;
}
.user__info--item {
  margin-bottom: 20px;
}

.user__info--label {
  color: #a9a9a9;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.user__info--value {
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
}
.user__image {
  display: block;
  cursor: pointer;
}

.user__slider .swiper-slide img {
  margin: 0;
  padding: 0;
  border-radius: 4px;
  width: 88px !important;
  height: 88px !important;
  object-fit: cover;
}
.user-slider .swiper-slide,
.user-slider .modal-swiper {
  display: flex !important;
  justify-content: center !important;
}
.user__slider .swiper-slide {
  cursor: pointer;
}

.swiper-pagination-bullet {
  background-color: #ffffff !important;
}

.no-photo-plsh {
  height: 100%;
  text-align: center;
  color: #a9a9a9;
}
.error__title {
  font-size: 23px;
  position: absolute;
  left: 15%;
  top: 10px;
}
.header {
  color: #fff;
  position: relative;
}
.header__title {
  position: absolute;
  white-space: nowrap;
  left: 15%;
  font-size: 23px;
  font-family: 'Arial', sans-serif;
  z-index: 1;
}

.header__wrap {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header__logo {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.header__logo--image {
  width: 55px;
  display: block;
  font-size: 0;
  line-height: 0;
}
.header__logo--text {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.1em;
  font-family: 'BarbadosCyr', sans-serif;
}

.header__wrap .main-header-right {
  position: absolute;
  right: 0;
}

.header__wrap .burger-menu {
  top: 50px;
}
@media screen and (max-width: 1080px) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
  .header__wrap {
    height: 65px;
    display: flex;
  }
  .header__logo {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .header__logo--image {
    width: 45px;
  }
  .header__title {
    bottom: -2em;
    font-size: 16px;
    left: 10%;
  }
  .error__title {
    font-size: 16px;
    left: 10%;
  }
  .root_tree {
    margin-top: 45px;
  }
}

@media screen and (max-width: 600px) {
  .user {
    width: 400px;
  }
  .modal-swiper .swiper-slide img {
    height: 65% !important;
  }
  .modal-swiper .swiper-button-prev:after,
  .modal-swiper .swiper-button-next:after {
    font-size: 35px;
  }

  .modal-swiper .swiper-button-prev {
    left: -4px !important;
  }
  .modal-swiper .swiper-button-next {
    right: -4px !important;
  }
}

@media screen and (max-width: 450px) {
  .user {
    width: 350px;
    padding: 25px;
  }

  .user__slider .swiper-slide img {
    width: 75px !important;
    height: 75px !important;
  }
}

@media screen and (max-width: 390px) {
  .user {
    width: 300px;
    padding: 20px;
  }
  .user-slider .swiper {
    padding: 0 6px !important;
  }
  .user-slider .swiper-button-prev::after,
  .user-slider .swiper-button-next::after {
    font-size: 16px !important;
  }
  .user-slider .swiper-button-next {
    right: -9px !important;
  }
  .user__slider .swiper-slide img {
    width: 70px !important;
    height: 70px !important;
  }
}
