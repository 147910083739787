.burger-menu {
  display: flex;
  flex-direction: column;
  width: 206px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  z-index: 2;
  background: #35393d;
}

.burger-menu .burger-menu-item {
  background: #35393d;
  color: #f6f6f6 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 12px 20px;
  border: none;
  text-align: left;
}

.burger-menu .burger-menu-item:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.burger-menu .burger-menu-item:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.burger-menu .burger-menu-item:not(:last-child) {
  border-bottom: 1px solid #46494e;
}
.burger-menu-item.active {
  color: #799bff !important;
}

.burger-menu-item.active::after {
  display: none;
}
