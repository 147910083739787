.pp-container {
  padding-top: 83px;
  font-size: 15px;
}

.pp-container h1 {
  font-size: 25px;
  margin-bottom: 18px;
}

.pp-container a {
  color: #ffffff;
  text-decoration: underline;
}

@media screen and (max-width: 740px) {
  .pp-container {
    padding-top: 87px;
  }
  .pp-container h1 {
    font-size: 22px;
    margin-bottom: 16px;
  }
}
