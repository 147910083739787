.heading-block {
  display: flex;
  align-items: center;
  gap: 16px;
}

h2.heading {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-family: Manrope, Arial, Helvetica, sans-serif;
  margin-bottom: 0;
}

.heading-services {
  font-size: 40px;
  line-height: 107%;
}
@media screen and (max-width: 920px) {
  .heading-block {
    gap: 14px;
  }
  h2.heading {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1480px) {
  .heading-services {
    font-size: 35px;
  }
}

@media screen and (max-width: 1080px) {
  .heading-services {
    font-size: 30px;
  }
}

@media screen and (max-width: 740px) {
  .heading-services {
    font-size: 22px;
  }
}
